import React from 'react';
import Grid from '@mui/material/Grid';

type Props = {
    readonly children?: React.ReactNode;
};

export const FormPageWrapper: React.FunctionComponent<Props> = ({ children }: Props) => (
    <Grid container justifyContent="center">
        <Grid item sx={{ pb: 2 }} xs={12} sm={9} lg={9} xl={6}>
            {children}
        </Grid>
    </Grid>
);
